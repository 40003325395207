import React from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import PaymentIco from "@material-ui/icons/Payment"
import { LINKS } from "../../constants/links"
import Restaurant1Img from "../../images/tmp_restaurants/ms-free-food-family-2.jpg"
import Restaurant2Img from "../../images/tmp_restaurants/ms-free-food-family-3.jpg"
import Restaurant3Img from "../../images/tmp_restaurants/ms-free-food-woman-hand.jpg"
import Restaurant4Img from "../../images/tmp_restaurants/ms-free-food-hamburger.jpg"

const restaurants = [{
  name: "Acquario",
  description: "Eleganckie wnętrze, nowoczesna otwarta kuchnia, minimalistyczne menu. Oto krótki opis Restauracji Acquario, znajdującej się na szóstym piętrze Hotelu Monopol. Przeszklone ściany ukazują niezwykłą panoramę historycznego Wrocławia, a przylegający do restauracji przestronny taras.",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant1Img,
}, {
  name: "Amber Room",
  description: "Restauracja Amber Room mieści się w zabytkowym Pałacu Sobańskich w samym centrum Warszawy. Amber Room specjalizuje się w nowoczesnej odsłonie kuchni polskiej, wykorzystując najnowsze trendy kulinarne, takie jak metoda sous vide czy slow food, która zajmuje...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant2Img,
}, {
  name: "A nóż widelec",
  description: "Odwiedzając A nóż widelec, wkraczasz w wielowymiarowy świat smaków i aromatów kuchni wielkopolskiej jak również europejskiej. Tutaj tradycja spotyka się z nowoczesnością, prezentując innowacyjną wizję kulinarnego dziedzictwa regionu. Czeka tu na Ciebie karta autorskich...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant3Img,
}, {
  name: "Appassionata",
  description: "Menu restauracji Appassionata to ukłon w stronę filozofii, jaką prekursorzy kreatywnej kuchni utworzyli wokół tego zagadnienia. Sięganie po tradycyjne receptury, staranność w dobieraniu produktów oraz ścisła wiedza na temat procesów zachodzących podczas gotowania pozwala...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant4Img,
}, {
  name: "Art Restauracja",
  description: "Nasza autorska restauracja to niezwykła przestrzeń stworzona w jednym z najstarszych zakątków miasta Kraka. Doskonałe miejsce, by zatrzymać się na chwilę lub trochę dłużej. Nowoczesne spojrzenie na kuchnię polską. Produkty najwyższej jakości od lokalnych producentów, a także...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant1Img,
}, {
  name: "Atelier Amaro",
  description: "Pracownia kulinarna założona przez Wojciecha Modesta Amaro w 2011 roku, w której „natura spotyka się z nauką”, by na nowo odkrywać i definiować kuchnię polską. W oparciu o stworzony 52 tygodniowy Kalendarz Natury, Amaro przeczesuje naturalne obszary Polski w poszukiwaniu bioróżnorodności,...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant2Img,
}, {
  name: "Biesiada Opolska",
  description: "Biesiada Opolska – czyli historia opolskiej gastronomii pisana na nowo.Miejsce, w którym stworzyliśmy Biesiadę Opolską od zawsze kojarzyło się mieszkańcom miasta z wykwintną kuchnią. To tu, w narożu Rynku u zbiegu ulic Książąt Opolskich i Osmańczyka powojenna sława kamienicy...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant3Img,
}, {
  name: "Boccanera",
  description: "Boccanera to wyjątkowe miejsce na kulinrnej mapie Krakowa. Kochamy prostą, włoską kuchnię, opartą na oryginalnych składnikach. Uwielbiamy nowoczesną muzykę na żywo. Delektujemy się smakiem tradycyjnych koktajli i rozkoszujemy się autorskimi propozycjami. A najbardziej lubimy, gdy wszystkie...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant4Img,
}, {
  name: "Acquario",
  description: "Eleganckie wnętrze, nowoczesna otwarta kuchnia, minimalistyczne menu. Oto krótki opis Restauracji Acquario, znajdującej się na szóstym piętrze Hotelu Monopol. Przeszklone ściany ukazują niezwykłą panoramę historycznego Wrocławia, a przylegający do restauracji przestronny taras.",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant1Img,
}, {
  name: "Amber Room",
  description: "Restauracja Amber Room mieści się w zabytkowym Pałacu Sobańskich w samym centrum Warszawy. Amber Room specjalizuje się w nowoczesnej odsłonie kuchni polskiej, wykorzystując najnowsze trendy kulinarne, takie jak metoda sous vide czy slow food, która zajmuje...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant2Img,
}, {
  name: "A nóż widelec",
  description: "Odwiedzając A nóż widelec, wkraczasz w wielowymiarowy świat smaków i aromatów kuchni wielkopolskiej jak również europejskiej. Tutaj tradycja spotyka się z nowoczesnością, prezentując innowacyjną wizję kulinarnego dziedzictwa regionu. Czeka tu na Ciebie karta autorskich...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant3Img,
}, {
  name: "Appassionata",
  description: "Menu restauracji Appassionata to ukłon w stronę filozofii, jaką prekursorzy kreatywnej kuchni utworzyli wokół tego zagadnienia. Sięganie po tradycyjne receptury, staranność w dobieraniu produktów oraz ścisła wiedza na temat procesów zachodzących podczas gotowania pozwala...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant4Img,
}, {
  name: "Art Restauracja",
  description: "Nasza autorska restauracja to niezwykła przestrzeń stworzona w jednym z najstarszych zakątków miasta Kraka. Doskonałe miejsce, by zatrzymać się na chwilę lub trochę dłużej. Nowoczesne spojrzenie na kuchnię polską. Produkty najwyższej jakości od lokalnych producentów, a także...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant1Img,
}, {
  name: "Atelier Amaro",
  description: "Pracownia kulinarna założona przez Wojciecha Modesta Amaro w 2011 roku, w której „natura spotyka się z nauką”, by na nowo odkrywać i definiować kuchnię polską. W oparciu o stworzony 52 tygodniowy Kalendarz Natury, Amaro przeczesuje naturalne obszary Polski w poszukiwaniu bioróżnorodności,...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant2Img,
}, {
  name: "Biesiada Opolska",
  description: "Biesiada Opolska – czyli historia opolskiej gastronomii pisana na nowo.Miejsce, w którym stworzyliśmy Biesiadę Opolską od zawsze kojarzyło się mieszkańcom miasta z wykwintną kuchnią. To tu, w narożu Rynku u zbiegu ulic Książąt Opolskich i Osmańczyka powojenna sława kamienicy...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant3Img,
}, {
  name: "Boccanera",
  description: "Boccanera to wyjątkowe miejsce na kulinrnej mapie Krakowa. Kochamy prostą, włoską kuchnię, opartą na oryginalnych składnikach. Uwielbiamy nowoczesną muzykę na żywo. Delektujemy się smakiem tradycyjnych koktajli i rozkoszujemy się autorskimi propozycjami. A najbardziej lubimy, gdy wszystkie...",
  open: "10:00 - 20:00",
  location: "al. 29 listopada 42/18",
  emails: ["contact@test.com", "info@test.com", "marketing@test.com", "orders@test.com"],
  phones: ["+48 668975828", "+48 717242192", "+48 8702452"],
  img: Restaurant4Img,
}]

const StyledRestaurantsListWrapper = styled.div`
  padding: 0;
`
const StyledHeader = styled.h5`
    font-size: 15px;
    flex-shrink: 0;
    margin: 0;
`
const StyledExpansionPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledAddressWrapper = styled.h6`
  font-size: 16px;
  margin: 0 0 40px;
  display: flex;
  justify-content: space-between;
`
const StyledContactWrapper = styled.div`
  margin: 20px 0;
  font-style: italic;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
`
const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
`

const RestaurantList = () => {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const RestaurantLink = React.forwardRef((props, ref) => (
    <Link innerRef={ref} to={LINKS.PAYMENT} {...props} />
  ))

  return (
    <StyledRestaurantsListWrapper>
      {restaurants.map((restaurant, key) => {
        const panelId = "panel" + key
        return (<ExpansionPanel expanded={expanded === panelId} onChange={handleChange(panelId)} key={"panel" + key}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls={panelId + "-content"}
              id={panelId + "-header"}>
              <StyledHeader>{restaurant.name}</StyledHeader>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <StyledExpansionPanelWrapper>
                <img src={restaurant.img} alt={restaurant.name}/>
                <StyledAddressWrapper>
                  <span>{restaurant.location}</span>
                  <span>{restaurant.open}</span>
                </StyledAddressWrapper>
                <Typography>
                  <Box>
                    {restaurant.description}
                  </Box>
                  <StyledContactWrapper>
                    <div>
                      <b>Kontakt:</b>
                      {restaurant.phones.map((phone, key) => <div key={key}>{phone}</div>)}
                    </div>
                    <div>
                      <br/>
                      {restaurant.emails.map((email, key) => <div key={key}>{email}</div>)}
                    </div>
                  </StyledContactWrapper>
                  <StyledButtonsWrapper>
                    <Button variant="contained" color="primary" size="large" component={RestaurantLink}>
                      <PaymentIco/>
                      <span>Zapłać</span>
                    </Button>
                  </StyledButtonsWrapper>
                </Typography>
              </StyledExpansionPanelWrapper>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </StyledRestaurantsListWrapper>
  )
}

export default RestaurantList