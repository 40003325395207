import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RestaurantList from "../components/restaurants/restaurantsList"

const RestaurantsPage = () => {

  return (
    <Layout>
      <SEO title="Home"/>
      <RestaurantList/>
    </Layout>
  )
}

export default RestaurantsPage
